const getCancellationsPlans = ({
    daysBeforeBooking = 0,
    total = 0,
    serviceFees = 0,
    isCanceledByClient = false,
    isArtist = false,
  }) => {
  if (isArtist && !isCanceledByClient) return total;
  if (!isCanceledByClient) return total;
  if (daysBeforeBooking > 30) return 0;
  if (daysBeforeBooking >= 2) return (total + serviceFees) / 2;
  if (daysBeforeBooking < 2) return serviceFees;
};


export default getCancellationsPlans;
