<template>
  <form
    @submit.prevent="handleCancelBooking"
    novalidate
    class="booking-cancel__wrapper">
    <header class="booking-cancel__header">
      <router-link
        :to="{ name: 'ClientBookingDetails', params: { id: booking.ID } }"
        class="mr-md">
        <Icon
          :variant="'arrow-left-outline'"
          :size="'xs'"
          :backdropColor="'white'">
        </Icon>
      </router-link>
      <Heading :level="'h3'">
        {{ $t('artist.dashboard.client.booking.cancellation.title') }}
      </Heading>
      <Paragraph class="booking-cancel__subtitle">
        {{ $t('artist.dashboard.client.booking.cancellation.subtitle') }}
      </Paragraph>
    </header>

    <div class="mb-lg">
      <Heading
        :tag="'h4'"
        :level="'h4'"
        isUppercased
        class="mb-lg">
        {{ $t('artist.dashboard.client.booking.cancellation.details') }}
      </Heading>
      <dl class="booking-cancel__pricing-list">
        <template v-for="(pricingField, index) in pricingFields">
          <Paragraph
            :tag="'dt'"
            :size="'sm'"
            :key="`pricing-field-label-${index}`">
            {{ pricingField.label }}
          </Paragraph>
          <Paragraph
            :tag="'dd'"
            :key="`pricing-field-value-${index}`">
            {{ pricingField.value }} €
          </Paragraph>
        </template>

        <div class="booking-cancel__pricing-total">
          <Paragraph
            :tag="'dt'"
            isBold>
            {{ $t('artist.dashboard.client.booking.cancellation.totalRefund') }}
          </Paragraph>
          <Paragraph :tag="'dd'">
            {{ refundTotal }} €
          </Paragraph>
        </div>
      </dl>
    </div>

    <Loader
      v-if="isLoading"
      class="mt-auto">
    </Loader>
    <footer v-else>
      <Button
        :isFluid="!$mediaQueries.isDesktop"
        type="submit"
        class="mt-auto mb-md">
        {{ $t('artist.dashboard.client.booking.cancellation.cta') }}
      </Button>
      <Paragraph
        v-html="$t('artist.dashboard.client.booking.cancellation.terms')"
        :size="'sm'">
      </Paragraph>
    </footer>
  </form>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapActions,
}                             from 'vuex';

import Icon                   from '../../../components/atoms/Icon/a-Icon.vue';
import Heading                from '../../../components/atoms/Heading/a-Heading.vue';
import Loader                 from '../../../components/atoms/Loader/a-Loader.vue';
import Paragraph              from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button                 from '../../../components/atoms/Button/a-Button.vue';
import CANCELLATION_PLANS     from '../../../constants/cancellationsPlans.js';


export default {
  name: 'p-BookingCancel',
  components: {
    Icon,
    Heading,
    Loader,
    Paragraph,
    Button,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters({ isArtist: 'User/IS_ARTIST_USER' }),
    pricingFields() {
      return [
        {
          label: this.$t('common.performance'),
          value: Number(this.artistPrice).toFixed(2),
        },
        {
          label: this.$t('artist.dashboard.client.booking.cancellation.taxes'),
          value: Number(this.serviceFees).toFixed(2),
        },
        {
          label: this.$t('artist.dashboard.client.booking.cancellation.fees'),
          value: this.cancellationFees > 0
            ? `- ${Number(this.cancellationFees ?? 0).toFixed(2)}`
            : 0,
        },
      ];
    },
    booking() {
      return this.user.Bookings?.find(({ ID }) => ID === this.id) ?? {};
    },
    clientPrice() {
      return Number((this.booking.PriceForClient / 100).toFixed(2));
    },
    artistPrice() {
      return Number((this.booking.PriceForArtist / 100).toFixed(2));
    },
    serviceFees() {
      return Number((this.clientPrice - this.artistPrice).toFixed(2));
    },
    daysBeforeBooking() {
      const millisecondsInADay = 86_400_000;
      const millisecondsBeforeBooking = ((this.booking.EventDate * 1000) - Date.parse(new Date()));

      return Math.ceil(millisecondsBeforeBooking / millisecondsInADay);
    },
    cancellationFees() {
      const details = {
        daysBeforeBooking: this.daysBeforeBooking,
        total: this.clientPrice,
        serviceFees: this.serviceFees,
        isCanceledByClient: this.booking.IsCanceledByClient || !this.isArtist,
        isArtist: this.isArtist,
      };

      return CANCELLATION_PLANS(details);
    },
    refundTotal() {
      return Number(this.clientPrice - this.cancellationFees).toFixed(2);
    },
  },
  methods: {
    ...mapActions({
      cancelBooking: 'SearchArtist/CANCEL_BOOKING',
    }),
    async handleCancelBooking() {
      try {
        this.isLoading = true;

        const params = {
          isCancelledByArtist: this.isArtist,
          bookingID: this.id,
        };

        await this.cancelBooking(params);

        this.$router.push({
          name: this.isArtist ? 'ArtistDashboardBooking' : 'ClientBookingDetails',
          params: { id: this.id },
          query: { isSuccess: true },
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
}

</script>

<style lang="scss">

.booking-cancel {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);

    @media screen and ($desktop) {
      max-width: 75%;
      row-gap: var(--space-xl);
    }
  }

  &__header {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-row-gap: var(--space-sm);
    align-items: center;
    margin-bottom: var(--space-lg);

    @media screen and ($desktop) {
      grid-column: 1 / -1;
      margin-bottom: 0;
    }
  }

  &__subtitle {
    grid-column: 1 / -1;
  }

  &__pricing-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: var(--space-sm);
    align-items: center;

    dd {
      justify-self: end;
    }
  }

  &__pricing-total {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: var(--space-sm);
    border-top: 1px solid var(--color-grey-empty);
  }

}

</style>
